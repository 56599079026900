exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-templates-about-us-about-us-tsx": () => import("./../../../src/templates/AboutUs/AboutUs.tsx" /* webpackChunkName: "component---src-templates-about-us-about-us-tsx" */),
  "component---src-templates-about-us-subpages-history-history-tsx": () => import("./../../../src/templates/AboutUs/subpages/History/History.tsx" /* webpackChunkName: "component---src-templates-about-us-subpages-history-history-tsx" */),
  "component---src-templates-about-us-subpages-management-management-tsx": () => import("./../../../src/templates/AboutUs/subpages/Management/Management.tsx" /* webpackChunkName: "component---src-templates-about-us-subpages-management-management-tsx" */),
  "component---src-templates-about-us-subpages-technologies-technologies-tsx": () => import("./../../../src/templates/AboutUs/subpages/Technologies/Technologies.tsx" /* webpackChunkName: "component---src-templates-about-us-subpages-technologies-technologies-tsx" */),
  "component---src-templates-blog-blog-tsx": () => import("./../../../src/templates/Blog/Blog.tsx" /* webpackChunkName: "component---src-templates-blog-blog-tsx" */),
  "component---src-templates-case-studies-case-studies-tsx": () => import("./../../../src/templates/CaseStudies/CaseStudies.tsx" /* webpackChunkName: "component---src-templates-case-studies-case-studies-tsx" */),
  "component---src-templates-contact-us-contact-us-tsx": () => import("./../../../src/templates/ContactUs/ContactUs.tsx" /* webpackChunkName: "component---src-templates-contact-us-contact-us-tsx" */),
  "component---src-templates-events-events-tsx": () => import("./../../../src/templates/Events/Events.tsx" /* webpackChunkName: "component---src-templates-events-events-tsx" */),
  "component---src-templates-events-subpages-event-event-tsx": () => import("./../../../src/templates/Events/subpages/Event/Event.tsx" /* webpackChunkName: "component---src-templates-events-subpages-event-event-tsx" */),
  "component---src-templates-inside-dsr-main-inside-dsr-main-tsx": () => import("./../../../src/templates/InsideDsrMain/InsideDsrMain.tsx" /* webpackChunkName: "component---src-templates-inside-dsr-main-inside-dsr-main-tsx" */),
  "component---src-templates-main-main-tsx": () => import("./../../../src/templates/Main/Main.tsx" /* webpackChunkName: "component---src-templates-main-main-tsx" */),
  "component---src-templates-news-main-news-main-tsx": () => import("./../../../src/templates/NewsMain/NewsMain.tsx" /* webpackChunkName: "component---src-templates-news-main-news-main-tsx" */),
  "component---src-templates-press-details-press-details-tsx": () => import("./../../../src/templates/PressDetails/PressDetails.tsx" /* webpackChunkName: "component---src-templates-press-details-press-details-tsx" */),
  "component---src-templates-simple-page-simple-page-tsx": () => import("./../../../src/templates/SimplePage/SimplePage.tsx" /* webpackChunkName: "component---src-templates-simple-page-simple-page-tsx" */),
  "component---src-templates-tag-empty-tags-tsx": () => import("./../../../src/templates/Tag/EmptyTags.tsx" /* webpackChunkName: "component---src-templates-tag-empty-tags-tsx" */),
  "component---src-templates-tag-tag-tsx": () => import("./../../../src/templates/Tag/Tag.tsx" /* webpackChunkName: "component---src-templates-tag-tag-tsx" */),
  "component---src-templates-training-centre-main-subpages-training-centre-training-centre-tsx": () => import("./../../../src/templates/TrainingCentreMain/subpages/TrainingCentre/TrainingCentre.tsx" /* webpackChunkName: "component---src-templates-training-centre-main-subpages-training-centre-training-centre-tsx" */),
  "component---src-templates-training-centre-main-training-centre-main-tsx": () => import("./../../../src/templates/TrainingCentreMain/TrainingCentreMain.tsx" /* webpackChunkName: "component---src-templates-training-centre-main-training-centre-main-tsx" */),
  "component---src-templates-vacancies-subpages-vacancy-vacancy-tsx": () => import("./../../../src/templates/Vacancies/subpages/Vacancy/Vacancy.tsx" /* webpackChunkName: "component---src-templates-vacancies-subpages-vacancy-vacancy-tsx" */),
  "component---src-templates-vacancies-vacancies-tsx": () => import("./../../../src/templates/Vacancies/Vacancies.tsx" /* webpackChunkName: "component---src-templates-vacancies-vacancies-tsx" */)
}

