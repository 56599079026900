import { ROUTE_PATH } from '../../../../utils/constants'

const MENU_LINKS = {
  aboutUs: {
    name: 'О нас',
    path: '',
  },
  history: {
    name: 'История',
    path: 'history/',
  },
  leaders: {
    name: 'Руководство',
    path: 'management/',
  },
  technologies: {
    name: 'Технологии',
    path: 'technologies/',
  },
}

type IPathMenu = keyof typeof MENU_LINKS
export const menuRoutes = Object.keys(MENU_LINKS).map((key) => {
  const route = MENU_LINKS[key as IPathMenu]
  const to = `${ROUTE_PATH.aboutUs.path}${route.path}`
  return {
    name: route.name,
    path: to,
  }
})
const menuPath = menuRoutes.map(({ path }) => path)
export const shouldPath = (path: string) => menuPath.includes(path)
