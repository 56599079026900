import GitInfo from 'react-git-info/macro'

export const GATSBY_GITLAB_APP_ID = process.env.GATSBY_GITLAB_APP_ID
export const GATSBY_GITLAB_URL = process.env.GATSBY_GITLAB_URL
export const GIT_BRANCH = process.env.GATSBY_GIT_BRANCH || GitInfo().branch

export const API_URL = process.env.GATSBY_API_URL
export const SITE_URL = process.env.GATSBY_SITE_URL

export const RECAPTCHA_KEY = process.env.RECAPTCHA_KEY
export const VK_APP_ID = process.env.VK_APP_ID

export const ROUTE_PATH = {
  aboutUs: {
    name: 'О компании',
    path: '/aboutUs/',
  },
  events: {
    name: 'События',
    path: '/events/',
  },
  news: {
    name: 'Новости',
    path: '/news/',
  },
  blog: {
    name: 'Блог',
    path: '/blog/',
  },
  vacancies: {
    name: 'Вакансии',
    path: '/vacancies/',
  },
  trainingCenter: {
    name: 'Учебный центр',
    path: '/student/',
  },
  insideDsr: {
    name: 'Жизнь компании',
    path: '/inside-dsr/',
  },
  weCreate: {
    name: 'Мы создаём',
    path: '/examination/',
  },
  principles: {
    name: 'Принципы',
    path: '/career/',
  },
  faq: {
    name: 'FAQ',
    path: '/faq/',
  },
  contacts: {
    name: 'Контакты',
    path: '/contacts/',
  },
}

export type IPath = keyof typeof ROUTE_PATH

// eslint-disable-next-line no-shadow
export enum Colors {
  PRIMARY = 'primary',
  VIOLET = 'violet',
  BLUE = 'blue',
  BLUE_DARK = 'blueDark',
  GREEN = 'green',
  GREEN_DARK = 'greenDark',
}

// eslint-disable-next-line no-shadow
export enum TextColors {
  WHITE = 'white',
  DARK = 'dark',
}

// eslint-disable-next-line no-shadow
export enum ButtonArrowVariant {
  YELLOW = 'yellow',
  ORANGE = 'orange',
  GREEN = 'green',
  GREEN_LIGHT = 'lightGreen',
  WHITE = 'white',
  BACK = 'back',
}

export type BackgroundColorType = 'white' | 'grey' | 'dark'

type RecordColors = Record<Colors, string>
type RecordTextColors = Record<TextColors, string>

export const ColorsArray = Object.values(Colors)
export const TextColorsArray = Object.values(TextColors)

export type ColorsType = keyof RecordColors
export type TextColorsType = keyof RecordTextColors

export const SLUG_PATTERN = '^[a-z0-9]+(?:-[a-z0-9]+)*$'
export const TAG_PATTERN = '^[a-zA-Z/]+(?:-[/a-zA-Z0-9]+)*$'

export interface Frontmatter<TFrontmatter> {
  frontmatter: TFrontmatter
}

export interface GraphQuery<TQuery> {
  data: TQuery
}

export interface AllMarkdownRemark<TNode> {
  allMarkdownRemark: {
    nodes: TNode[]
  }
}

export interface MarkdownRemark<TNode> {
  markdownRemark: TNode
}

export type AllMarkdownRemarkQuery<TNode> = GraphQuery<AllMarkdownRemark<TNode>>
export type MarkdownRemarkQuery<TNode> = GraphQuery<MarkdownRemark<TNode>>
