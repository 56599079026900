/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import 'normalize.css'
import './src/styles/global.scss'
import { shouldPath } from './src/components/AboutUsPageLayout/MenuSection/Menu/routesMenu'
console.log('version: ', process.env.APP_VERSION)

const defaultSavedScroll = 330

export const shouldUpdateScroll = ({ prevRouterProps, pathname }) => {
  // save the scroll value in the aboutUs component

  if (shouldPath(pathname)) {
    const prevPath = prevRouterProps?.location.pathname
    const prevScrollState =
      sessionStorage.getItem(`@@scroll|${prevPath}|${prevRouterProps?.location?.key}`) ??
      parseInt(localStorage.getItem('scroll')) ??
      defaultSavedScroll
    window.scrollTo(0, prevScrollState)
    if (shouldPath(prevPath)) {
      const $node = (elem) => document.getElementById(elem)
      const positionNode = $node(pathname).offsetLeft
      if (positionNode > 0) $node('menu_aboutUs').scrollTo(positionNode, 0)
      return false
    }
  }

  return true
}
